<template>
  <div>
    <section class="bg-white no-bg">
      <div class="bg content">
        <div class="intro">
          <p class="intro__title">Presse</p>
          <p>Merci pour ces articles !</p>
        </div>
        <vue-masonry-wall :items="items" :options="options">
          <template v-slot:default="{ item }">
            <div class="item">
              <h3 class="title">
                <span>{{ item.title }}</span>
                <span class="date">{{ item.date }}</span>
                <a :href="item.url" target="_blank" v-if="item.url" class="pdf"
                  >PDF</a
                >
              </h3>
              <div class="image">
                <img :src="item.image" />
                <span v-if="item.image2"><img :src="item.image2" /></span>
              </div>
            </div>
          </template>
        </vue-masonry-wall>
      </div>
    </section>
  </div>
</template>
  
  <script>
import VueMasonryWall from "vue-masonry-wall";
export default {
  name: "Presse",
  components: { VueMasonryWall },
  data() {
    return {
      options: {
        width: 600,
        padding: {
          default: 20,
          1: 10,
          2: 20,
        },
      },
      items: [
        {
          image: "img/presse/mychic_2023_12.jpg",
          title: "My chic residence",
          date: "décembre 2023",
          url: "img/presse/mychic_2023_12.pdf",
        },
        {
          image: "img/presse/mychicresidence_118.jpg",
          title: "My chic residence",
          url: "img/presse/mychicresidence_118.pdf",
        },
        {
          image: "img/presse/6bien_banner.jpg",
          image2: "img/presse/6bien_12.jpg",
          title: "6 Bien",
        },
        {
          image: "img/presse/tribune_2022_11.jpg",
          title: "Tribune de Lyon",
        },
        {
          image: "img/presse/petitscommerces_2022.jpg",
          title: "Petits commerces",
        },
        {
          image: "img/presse/tribune_2022_01.jpg",
          title: "Tribune de Lyon",
        },
        {
          image: "img/presse/lebonbon_2021_12.jpg",
          title: "Le bonbon",
        },
        {
          image: "img/presse/progres_2021_12.jpg",
          title: "Le Progrès",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss">
$sub: #999999;

.item .image {
  margin-top: 1rem;
}
.item .title .date {
  margin-left: 2rem;
  color: $sub;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
}
.pdf {
  display: inline-block;
  padding-left: 2rem;
  margin-left: 4rem;
  background: url("~@/assets/open_gray.svg") no-repeat left center;
  background-size: 30%;
  color: $sub;
  font-size: 1.3rem;
  font-style: normal;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 600;
}
@media (min-width: 960px) {
  .item .image {
    margin-top: 2rem;
  }
}
</style>